.dashboard {
    position: relative;
}

.dashboard .enq {
    margin-top: 2%;
}

.dashboard .afff {
    background-image: url('../../Assets/Untitled\ design\ \(1\).svg');
    background: cover;
    background-size: cover;
    height: 300px;
    background-repeat: no-repeat;
    position: relative;
}

.dashboard .h2tag {
    margin-top: 20px;
    color: rgb(113, 112, 112);
}

.dashboard .h2taginr {
    margin-top: 20px;
    color: rgb(18, 226, 87);
}