/* Logout Wrapper */
.logout-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  
    /* Semi-transparent background */
    padding: 20px;
}

.logout-content {
    text-align: center;
    background-color: #fff;
    padding: 30px 40px;
    border-radius: 8px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
 
}

.logout-content h2 {
    font-size: 28px;
    color: #333;
    margin-bottom: 15px;
}

.logout-description {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.logout-timer {
    font-size: 18px;
    color: #e63946;
    margin-bottom: 25px;
    font-weight: bold;
}

.logout-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.btn {
    padding: 12px 25px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-primary {
    background-color: #007bff;
    color: white;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.btn-secondary {
    background-color: #6c757d;
    color: white;
}

.btn-secondary:hover {
    background-color: #5a6268;
}

.logout-info {
    margin-top: 20px;
    font-size: 14px;
    color: #333;
}

.logout-info p {
    margin: 0;
    color: #666;
}