.settings-container {

    margin: 0 auto;
    padding: 20px;
  }
  
  .settings-title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 30px;
    color: #333;
  }
  
  .settings-section {
    margin-bottom: 40px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .settings-section h3 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    color: #555;
  }
  
  .input-group input, .input-group textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  textarea {
    min-height: 100px;
  }
  
  .checkbox-group {
    margin-bottom: 20px;
  }
  
  .checkbox-group label {
    font-size: 16px;
    color: #555;
  }
  
  .checkbox-group input {
    margin-right: 10px;
  }
  
  button {
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: white;
    width: 100%;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-secondary {
    background-color: #28a745;
    color: white;
  }
  
  .btn-secondary:hover {
    background-color: #218838;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 15px;
  }
  