.icon-view,
.icon-download {
  cursor: pointer;
  font-size: 20px; /* Adjust the size of the icon */
  color: #007bff; /* Blue color */
  vertical-align: middle; /* Vertically align the icon */
  padding: 0 5px; /* Add some spacing around the icon */
}

.icon-view:hover,
.icon-download:hover {
  color: #0056b3; /* Darker blue color on hover */
}

.icon-view,
.icon-download {
  display: inline-block;
}
