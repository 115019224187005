.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 25px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 11111;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header-logo .logo {
    height: 40px;
    width: auto;
}

.header-user {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-name {
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.profile-photo {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
}