.linksContainer {
    display: flex;
    gap: 1rem;
  }
  
  .university-list h1 {
    margin-left: 20px;
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    width: 500px;
    text-align: center;
  }
  
  .closeButton {
    background-color: red;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
    min-width: 0px;
    width: 100%;
  }
  
  .bbbbbbb img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .linksContainer button {
    width: 100%;
  }
  
  .modalContent label {
    margin-top: 10px;
  }
  
  
  @media only screen and (max-width: 900px) {
    .modalContent {
      width: 330px;
      min-height: 350px;
      padding: 15px !important;
    }
  
    .modal-content button {
      width: 100%;
      margin-top: 10px;
    }
  
    .modalContent label {
      margin-top: 5px;
    }
  
    .react-international-phone-input {
      width: 100% !important;
      height: 30px !important;
      font-size: 10px !important;
    }
  
    .react-international-phone-country-selector-button {
      height: 30px !important;
    }
  
    .modalContent .form-container {
      font-size: 10px;
    }
  
    .modalContent .form-container h4 {
      font-size: 10px;
    }
  
    .university-list h1 {
      margin-left: 20px;
      font-size: 15px !important;
    }
  
    .modalContent .form-container h6 {
      font-size: 10px;
    }
  
    .modalContent .form-container .form-control,
    .modalContent .form-container .form-select {
      height: 30px;
    }
  
    .modalContent .form-container select {
      font-size: 10px;
    }
  
    .modalContent .form-container .form_sumbit button {
      font-size: 10px;
  
    }
  
    .modalContent .form-container .closeButton {
  
      font-size: 10px;
      height: 30px;
    }
  
    .modalContent .form-container input::placeholder {
      font-size: 10px;
  
    }
  
    .modalContent .form-container small {
      font-size: 8px;
    }
  }