.rdt_TableHeadRow {
    background-color: black;
}

.rdt_TableCol {
    background-color: black;
    color: white;
}

/* Change the column headers text color to black */
.react-data-table-component .rdt_TableHead {
    background-color: #f8f9fa;
    /* Optional: Set a light background color for the header */
}

.react-data-table-component .rdt_TableHead .rdt_TableHeaderCell {
    color: black !important;
    /* Change text color of column headers to black */
    font-weight: bold;
    /* Optional: Make header text bold */
}

/* Ensure the button does not wrap text */
button.btn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Fix the S.No column width */
.DataTable__cell {
    max-width: 70px;
}

/* Optionally, you can set a minimum width for columns if needed */
.DataTable__cell--sno {
    min-width: 70px;
}

.DataTable__cell--action {
    width: 120px;
}

/* For the whole table, set width to auto to prevent overflow */
.data-table {
    table-layout: auto;
}

/* Add your custom styles here for the modal content */
/* Modal content styling */
.modal-content {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    width: 400px;
    margin: 0 auto;
    /* Remove transform translateY(-50%) */
    position: relative;
    z-index: 1000; /* Ensure modal content is on top of overlay */
}

/* Modal overlay background styling */
.modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed; /* Ensure overlay covers the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; /* Center modal horizontally */
    align-items: center; /* Center modal vertically */
    z-index: 999; /* Set overlay z-index lower than modal content */
}

/* UTM input field styling */
.utm-input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

/* Copy button styling */
.copy-btn {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.copy-btn:hover {
    background-color: #0056b3;
    /* Darker blue on hover */
}

/* UTM description styling */
.utm-description {
    margin-top: 15px;
    font-size: 14px;
    color: #555;
}

/* Close button styling */
.close-btn {
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.close-btn:hover {
    background-color: #c82333;
    /* Darker red on hover */
}
