.sidebar {
    width: 250px;
    background-color: #343a40;
    color: #fff;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-logo {
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid #495057;
}

.sidebar-logo-img {
    height: 50px;
    width: auto;
}

.sidebar-nav {
    flex-grow: 1;
    padding-top: 20px;
}

.sidebar-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-item {
    margin: 0;
}

.sidebar-link {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    transition: background 0.3s;
}

.sidebar-link:hover {
    background-color: #495057;
}

.sidebar-icon {
    margin-right: 10px;
    font-size: 20px;
}

.sidebar-text {
    font-size: 16px;
    font-weight: 500;
}

.sidebar-link.active {
    background-color: #007bff;
}
